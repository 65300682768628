import React, { Component } from 'react';
import { Panel, ListGroup, ListGroupItem, Modal, Button, Form, Row, Col } from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataGrid from '../common_components/DataGrid.js';
import DataDropdown from '../common_components/DataDropdown';
import {getAPI, http_build_query, mapArrayToObject, convertTimestampToDate, openBirtReport, getLoggedInUser, constants, reverseDateForReport, validateForm, showFormValidationAlert, resetObject} from '../common_components/common.js';
import Config from '../Config';
import '../FormPage.css';
const { Translate } = require('react-i18nify');

const orderTypes = {
	'S': constants.salesOrder, // <Translate value='components.reports.salesOrder' />,
	'L': constants.labRequest, // <Translate value='components.reports.labRequest' />,
};

const internalOrdersModes = {
	'I': constants.include, // <Translate value='components.reports.include' />,
	'E': constants.exclude, // <Translate value='components.reports.exclude' />,
	'O': constants.only, // <Translate value='components.reports.only' />,
};

var lrTypes = {
	'C' : constants.filterColorMatch, // <Translate value='components.reports.colorMatch' />,
	'R' : constants.filterRnd, // <Translate value='components.reports.rnd' />,
	'P' : constants.filterRepull, // <Translate value='components.reports.repull' />,
};
	
const sortByList = {
	"S": constants.filterSales, // "Sales", 
	"P": constants.filterProfit, // "Profit"
};

const groupByList = {
	"customer": constants.filterTopCustomer, // "Top Customers", 
	"formula": constants.filterTopFormulas, // "Top Formulas", 
	"salesRep": constants.filterTopSalesReps, // "Top Sales Reps"
};

function ReportModal(props, contents) {
	const reportFormat = props.reportFormat ? props.reportFormat : 'pdf';
	// console.log("reportFormat in ReportModal(): ", reportFormat);
	return <Modal
		show={props.visible}
		onHide={props.onHide}
	>
		<Modal.Header closeButton>
			<Modal.Title>
				<Translate value='components.reports.reportOptions' />
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form className='form-page' horizontal>
				{props.children}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<DataDropdown
				label={<Translate value='components.reports.reportFormat' />}
				data={{pdf: 'PDF', xlsx: 'XLSX', html: 'HTML'}}
				action={(event) => props.onChangeReportFormat(event.target.value)}
				value={reportFormat}
			/>
			<Button bsStyle='primary' name={'viewReport'} onClick={() => props.onSubmit()}>
				<Translate value='components.reports.viewReport' />
			</Button>
		</Modal.Footer>
	</Modal>
}

function AgingReport(props) {
	const loggedInUser = getLoggedInUser();
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>

		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type='SingleSelect'
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		
		

	</ReportModal>;
}

function TrendingAgingReport(props) {
	const loggedInUser = getLoggedInUser();
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type='SingleSelect'
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.showTrendGraph' />} checked
			action={event => props.onChangeInput('showTrendGraph', event.target.checked)}
			value={props.showTrendGraph}
		/>
		
		{!bCustomerLoggedIn && Config.env != "mex" &&
		<DataDropdown label={<Translate value='components.reports.internationalCustomers' />} data={internalOrdersModes} 
			action={event => props.onChangeInput('internalOrders', event.target.value)}
			value={props.internalOrders}
		/>
		}
		{!bCustomerLoggedIn && Config.env != "mex" &&
		<InputText type='CheckBox' label={<Translate value='components.reports.bcmMex' />} checked
			action={event => props.onChangeInput('internationalCustomers', event.target.checked)}
			value={props.internationalCustomers}
		/>
		}
		

	</ReportModal>;
}

function PhAndViscosity(props) {
	const loggedInUser = getLoggedInUser();
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>

		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
		
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		}
		
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.internalOrders' />} data={internalOrdersModes} 
			action={event => props.onChangeInput('internalOrders', event.target.value)}
			value={props.internalOrders}
		/>
		}
		
		<InputText type='CheckBox' label={<Translate value='components.reports.specOutOfPh' />}
			action={event => props.onChangeInput('specOutOfPh', event.target.checked)}
			value={props.specOutOfPh}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.highPh' />}
			action={event => props.onChangeInput('highPh', event.target.checked)}
			value={props.highPh}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.lowPh' />}
			action={event => props.onChangeInput('lowPh', event.target.checked)}
			value={props.lowPh}
		/>
		
		<InputText type='CheckBox' label={<Translate value='components.reports.specOutOfViscosity' />}
			action={event => props.onChangeInput('specOutOfViscosity', event.target.checked)}
			value={props.specOutOfViscosity}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.highViscosity' />}
			action={event => props.onChangeInput('highViscosity', event.target.checked)}
			value={props.highViscosity}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.lowViscosity' />}
			action={event => props.onChangeInput('lowViscosity', event.target.checked)}
			value={props.lowViscosity}
		/>

	</ReportModal>;
}



function CreditMemo(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.creditMemo' />}
			data={props.creditMemos}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
	</ReportModal>;
}

function InventoryTransactions(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			label={<Translate value='components.reports.hideDetails' />}
			type='CheckBox'
			action={(event) => props.onToggleHideDetails()}
		/>
		<InputText
			label={<Translate value='components.reports.componentNumber' />}
			action={(event) => props.onChangeComponentNumber(event.target.value)}
			value={props.componentNumber}
		/>
	</ReportModal>
}

function InventoryVariance(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	/>
}

function InvoiceSummary(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
	</ReportModal>
}

function OrderListing(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.shipDate' />}
			type='DateTime' 			
			action={(event) => {
				const shipDate = convertTimestampToDate(event._d);
				props.onChangeShipDate(shipDate);
			}}
			value={props.shipDate}
		/>
	</ReportModal>
}

function PurchaseOrder(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type='SingleSelect'
			data={props.purchaseOrders}
			label={<Translate value='components.reports.purchaseOrder' />}
			name={'purchaseOrder'}
			action={(event) => props.onChangePurchaseOrder(event.value)}
		/>
	</ReportModal>
}

function BillOfLading(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	/>
}

function BucketLabel(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.salesOrder' />}
			data={props.salesOrders}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
	</ReportModal>
}

function PackingSlip(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.salesOrder' />}
			data={props.salesOrders}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
	</ReportModal>
}


function Historical(props) {
	const loggedInUser = getLoggedInUser();
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	
	// console.log("Historical() - props.errors: ", props.errors, ", validationFields: ", validationFields);
	var setupModes = {
		1: constants.setup, // <Translate value='components.reports.setup' />,
		0: constants.production, // <Translate value='components.reports.production' />,
	};
	
	const historicalReportTypes = {
		'J': constants.jobMultiColorReport, // <Translate value='components.reports.jobMultiColorReport' />,
		'I': constants.individualColorReport, // <Translate value='components.reports.individualColorReport' />,
		'P': constants.pressSpecificReport, // <Translate value='components.reports.pressSpecificReport' />,
	};
	
	var comparisonCriteria = {
		0: 'Delta E',
		1: constants.filterAbsoluteDensity, // 'Absolute Density',
	};
	
	const statuses = {
		'0' : constants.filterActive, // 'Active',
		'1': constants.filterInactive, // 'Inactive',
		'-1': constants.filterAll, // 'All',
	}
	
	const presses = mapArrayToObject(props.presses, 'value', 'label');
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
		reportFormat={props.reportFormat}
	>
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			required={validationFields.customer == 'required'} 
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
			errMsg={props.errors.customer}
		/>
		}
		<DataDropdown
			required={validationFields.type == 'required'} 
			label={<Translate value='components.reports.reportType' />}
			data={historicalReportTypes}
			action={(event) => props.onChangeInput('type', event.target.value)}
			value={props.type}
			errMsg={props.errors.type}
		/>
		<DataDropdown
			required={validationFields.status == 'required'} 
			label={<Translate value='components.labels.status' />}
			data={statuses}
			action={(event) => props.onChangeState('retired', event.target.value)}
			value={props.retired}
			errMsg={props.errors.retired}
		/>
		
		{props.type == 'J' &&
		<InputText
			required={validationFields.jobId == 'required'} 
			type={'SingleSelect'}
			data={props.jobs}
			label={<Translate value='components.reports.jobNameOrDescription' />}
			name={'jobId'}
			action={event => {
				props.onChangeInput("jobId", event.value);
			}}
			errMsg={props.errors.jobId}
		/>
		}
		
		{props.type == 'I' &&
		<InputText
			required={validationFields.colorId == 'required'}
			type={'SingleSelect'}
			data={props.colors}
			label={<Translate value='components.reports.colorOrFormula' />}
			name={'colorId'}
			action={event => {
				props.onChangeInput("colorId", event.value);
			}}
			errMsg={props.errors.colorId}
		/>
		}
		
		{props.type == 'P' &&
		<DataDropdown
			required={validationFields.customerPressId == 'required'}
			label={<Translate value='components.reports.pressSpecific' />}
			data={presses}
			action={(event) => props.onChangeInput('customerPressId', event.target.value)}
			value={props.customerPressId}
			errMsg={props.errors.customerPressId}
		/>
		}
		
		<InputText type='DateTimestamp'
			required={validationFields.startDate == 'required'}
			label={<Translate value='components.reports.from' />} 
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				props.onChangeInput('startDate', value);
			}}
			errMsg={props.errors.startDate}
		/>
		
		<InputText type='DateTimestamp' 
			required={validationFields.endDate == 'required'}
			label={<Translate value='components.reports.to' />}
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				props.onChangeInput('endDate', value);
			}}
			errMsg={props.errors.endDate}
		/>
		{props.type == 'J' && props.jobColors.length > 0 && 
			<DataGrid 
				columns={['Include', 'Name', 'Compare Delta E', 'Compare Density']} 
				fields={['id', 'name', 'id', 'id']} 
				data={props.jobColors} 
				formatColumns={{
					0: {checkbox: {}, colCheckBound: 'chkInclude', callbackCheckbox: (event) => {props.onChecked(event, "jobColors", "chkInclude")}
					}, 
					2: {checkbox: {}, colCheckBound: 'chkCompareDE', callbackCheckbox: (event) => {props.onChecked(event, "jobColors", "chkCompareDE")}
					}, 
					3: {checkbox: {}, colCheckBound: 'chkCompareAbsDensity', callbackCheckbox: (event) => {props.onChecked(event, "jobColors", "chkCompareAbsDensity")}}
				}}
			/>
		}
		
		{props.type == 'P' && props.pressColors.length > 0 && 
			<DataGrid 
				columns={['Include', 'Name', 'Compare Delta E', 'Compare Density']} 
				fields={['id', 'name', 'id', 'id']} 
				data={props.pressColors} 
				formatColumns={{
					0: {checkbox: {}, colCheckBound: 'chkInclude', callbackCheckbox: (event) => {props.onChecked(event, "pressColors", "chkInclude")}
					}, 
					2: {checkbox: {}, colCheckBound: 'chkCompareDE', callbackCheckbox: (event) => {props.onChecked(event, "pressColors", "chkCompareDE")}
					}, 
					3: {checkbox: {}, colCheckBound: 'chkCompareAbsDensity', callbackCheckbox: (event) => {props.onChecked(event, "pressColors", "chkCompareAbsDensity")}}
				}}
			/>
		}
		<InputText type='DateTimestamp' 
			label={<Translate value='components.reports.compareFrom' />} 
			action={event => {
				// const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				const value = event._d ? convertTimestampToDate(event._d, true, true) : ((event.value == 0 && event.label == '') ? '' : event);
				// console.log("compareFrom action - event: ", event, ", value: ", value);
				props.onChangeInput('compareFromDate', value);
			}}
		/>

		<InputText type='DateTimestamp' 
			label={<Translate value='components.reports.compareTo' />} 
			action={event => {
				// const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				const value = event._d ? convertTimestampToDate(event._d, true, true) : ((event.value == 0 && event.label == '') ? '' : event);
				// console.log("compareTo action - event: ", event, ", value: ", value);
				props.onChangeInput('compareToDate', value);
			}}
		/>
		
		<DataDropdown
			required={validationFields.setupMode == 'required'}
			label={<Translate value='components.reports.mode' />}
			data={setupModes}
			action={(event) => props.onChangeInput('setupMode', event.target.value)}
			value={props.setupMode}
			optionAll={true}
			errMsg={props.errors.setupMode}
		/>
		
		{props.type == 'I' &&
		<DataDropdown
			required={validationFields.comparisonCriteria == 'required'}
			label={<Translate value='components.reports.comparisonCriteria' />}
			data={comparisonCriteria}
			action={(event) => props.onChangeInput('comparisonCriteria', event.target.value)}
			value={props.comparisonCriteria}
			errMsg={props.errors.comparisonCriteria}
		/>
		}
	</ReportModal>
}

function ColorMatch(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.salesReps}
			label={<Translate value='components.reports.salesRep' />}
			name={'salesRep'}
			action={event => props.onChangeInput('salesRep', event.value)}
		/>
		
		
	</ReportModal>
}


function CallReportSummary(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.vendors}
			label={<Translate value='components.reports.vendor' />}
			name={'component'}
			action={event => props.onChangeVendor(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.shippers}
			label={<Translate value='components.reports.shipper' />}
			name={'shipper'}
			action={event => props.onChangeShipper(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.salesReps}
			label={<Translate value='components.reports.salesRep' />}
			name={'salesRep'}
			action={event => props.onChangeInput('salesRep', event.value)}
		/>
		
		
	</ReportModal>
}

function SalesOrderLRShipment(props) {
	const loggedInUser = getLoggedInUser();
	const bVIPLoggedIn = loggedInUser.userTypeId == constants.vip_type_id;
	const bFinanceLoggedIn = loggedInUser.userTypeId == constants.finance_type_id;
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	const bSalesManagerLoggedIn = loggedInUser.userTypeId == constants.sales_manager_type_id;
	const bVIPSalesManagerFinance = bVIPLoggedIn || bFinanceLoggedIn || bSalesManagerLoggedIn;

	return(<ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
	{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
	}
	{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		}
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
		<InputText type='DateTime' 
			label={<Translate value='components.reports.compareFrom' />} 
			action={event => {
				// const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				const value = event._d ? convertTimestampToDate(event._d) : ((event.value == 0 && event.label == '') ? '' : event);
				// console.log("compareFrom action - event: ", event, ", value: ", value);
				props.onChangeInput('compareFromDate', value);
			}}
		/>

		<InputText type='DateTime' 
			label={<Translate value='components.reports.compareTo' />} 
			action={event => {
				// const value = event._d ? convertTimestampToDate(event._d, true, true) : event;
				const value = event._d ? convertTimestampToDate(event._d) : ((event.value == 0 && event.label == '') ? '' : event);
				// console.log("compareTo action - event: ", event, ", value: ", value);
				props.onChangeInput('compareToDate', value);
			}}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.summary' />} checked
			action={event => props.onChangeInput('summary', event.target.checked)}
			value={props.summary}
		/>
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.orderType' />} data={orderTypes} 
			action={event => props.onChangeInput('orderType', event.target.value)}
			value={props.orderType}
		/>
		}
		{props.orderType == 'L' && 
		<DataDropdown label={<Translate value='components.reports.lrType' />} data={lrTypes} 
		    action={event => props.onChangeInput('lrType', event.target.value)}
		    value={props.lrType}
	    />
		}
		
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.internationalCustomers' />} data={internalOrdersModes} 
			action={event => props.onChangeInput('internalOrders', event.target.value)}
			value={props.internalOrders}
		/>
		}
		
	</ReportModal>);
}

function TopComponent(props) {
	const loggedInUser = getLoggedInUser();

	return(<ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
	
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
			
		
	</ReportModal>);
}

function LbsSold(props) {
	const loggedInUser = getLoggedInUser();
	const bVIPLoggedIn = loggedInUser.userTypeId == constants.vip_type_id;
	const bFinanceLoggedIn = loggedInUser.userTypeId == constants.finance_type_id;
	const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
	const bSalesManagerLoggedIn = loggedInUser.userTypeId == constants.sales_manager_type_id;
	const bVIPSalesManagerFinance = bVIPLoggedIn || bFinanceLoggedIn || bSalesManagerLoggedIn;
	
	var dateTypes = {
		'O': constants.orderDate, // <Translate value='components.reports.orderDate' />,
		'S': constants.shipDate, // <Translate value='components.reports.shipDate' />,
	};

	var locations = {
		'D': constants.domestic, // <Translate value='components.reports.domestic' />,
		'I': constants.international, // <Translate value='components.reports.international' />,
		'A': constants.filterAll, // <Translate value='components.reports.all' />,
	};

	

	var formulaTypes = {
		88: constants.base, // <Translate value='components.reports.base' />,
		89: constants.finishedInk, // <Translate value='components.reports.finishedInk' />,
		90: constants.varnish, // <Translate value='components.reports.varnish' />,
		91: constants.other, // <Translate value='components.reports.other' />,
		163: constants.additive, // <Translate value='components.reports.additive' />,
		164: constants.dispersion, // <Translate value='components.reports.dispersion' />,
		165: constants.opv, // <Translate value='components.reports.opv' />,
		166: constants.blendingInk, // <Translate value='components.reports.blendingInk' />,
	};


	var priceBasedUponModes = {
		'F': constants.formula, // <Translate value='components.reports.formula' />,
		'SO': constants.salesOrder, // <Translate value='components.reports.salesOrder' />,
	};

	return(<ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText
			type={'SingleSelect'}
			data={props.salesReps}
			label={<Translate value='components.reports.salesRep' />}
			name={'salesRep'}
			action={event => props.onChangeInput('salesRep', event.value)}
		/>
		}
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.compareFrom' />} 
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('compareFromDate', value);
			}}
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.compareTo' />} 
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('compareToDate', value);
			}}
		/>
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.dateType' />} data={dateTypes} 
			action={event => props.onChangeInput('dateToUse', event.target.value)}
			value={props.dateToUse}
		/>
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.location' />} data={locations} 
			action={event => props.onChangeInput('location', event.target.value)}
			value={props.location}
		/>
		}
		
		<InputText type='CheckBox' label={<Translate value='components.reports.summary' />} checked
			action={event => props.onChangeInput('summary', event.target.checked)}
			value={props.summary}
		/>
		{!bCustomerLoggedIn &&
		<InputText type='CheckBox' label={<Translate value='components.reports.regularBilling' />} 
			action={event => props.onChangeInput('regularBilling', event.target.checked)}
			value={props.regularBilling}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText type='CheckBox' label={<Translate value='components.reports.consignment' />} 
			action={event => props.onChangeInput('consignment', event.target.checked)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText type='CheckBox' label={<Translate value='components.reports.consignmentBilling' />} 
			action={event => props.onChangeInput('consignmentBilling', event.target.checked)}
			value={props.consignmentBilling}
		/>
		}
		{(bVIPLoggedIn || bFinanceLoggedIn) && 
		<InputText type='CheckBox' label={<Translate value='components.reports.profitability' />} 
			action={event => props.onChangeInput('showProfit', event.target.checked)}
		/>
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.newSales' />} data={internalOrdersModes} 
			action={event => props.onChangeInput('newSales', event.target.value)}
			value={props.newSales}
		/>
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.orderType' />} data={orderTypes} 
			action={event => props.onChangeInput('orderType', event.target.value)}
			value={props.orderType}
		/>
		}
		{props.orderType == 'L' && 
		<DataDropdown label={<Translate value='components.reports.lrType' />} data={lrTypes} 
		    action={event => props.onChangeInput('lrType', event.target.value)}
		    value={props.lrType}
	    />
		}
		{props.orderType == 'L' &&
		   <InputText
		   type={'SingleSelect'}
		   data={props.labtechs}
		   label={<Translate value='components.reports.matchedBy' />}
		   name={'labtech'}
		   action={event => props.onChangeLabTech(event.value)}
	   />
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.formulaType' />} data={formulaTypes} 
			action={event => props.onChangeInput('formulaTypeLookupId', event.target.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.internalOrders' />} data={internalOrdersModes} 
			action={event => props.onChangeInput('internalOrders', event.target.value)}
			value={props.internalOrders}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText label={<Translate value='components.reports.priceFrom' />} 
			action={event => props.onChangeInput('priceRangeFrom', event.target.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<InputText label={<Translate value='components.reports.priceTo' />} 
			action={event => props.onChangeInput('priceRangeTo', event.target.value)}
		/>
		}
		{!bCustomerLoggedIn &&
		<DataDropdown label={<Translate value='components.reports.basedUpon' />} data={priceBasedUponModes}
			action={event => props.onChangeInput('priceBasedUpon', event.target.value)}
			value={props.priceBasedUpon}
		/>
		}
		{bVIPSalesManagerFinance &&
		<DataDropdown label={<Translate value='components.reports.groupBy' />} 
			data={groupByList}
			action={event => props.onChangeInput('groupBy', event.target.value)}
			value={props.groupBy}
		/>
		}
		{bVIPSalesManagerFinance &&
		<DataDropdown label={<Translate value='components.reports.sortBy' />} 
			data={sortByList}
			action={event => props.onChangeInput('sortBy', event.target.value)}
			value={props.sortBy}
		/>
		}
	</ReportModal>);
}

function ContainsComponents(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<InputText
			type={'MultiSelect'}
			data={props.components}
			label={<Translate value='components.reports.component' />}
			name={'component'}
			action={event => {props.onChangeComponent(event)}}
		/>
	</ReportModal>;
}

function FormulaChangeReason(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		
		
	</ReportModal>
}

function FormulaCostPriceChange(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		
		
	</ReportModal>
}

function ComponentCostPriceChange(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.components}
			label={<Translate value='components.reports.component' />}
			name={'component'}
			action={event => props.onChangeComponent(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.vendors}
			label={<Translate value='components.reports.vendor' />}
			name={'component'}
			action={event => props.onChangeVendor(event.value)}
		/>
		
		
	</ReportModal>
}

function FormulaCost(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
	</ReportModal>
}

function BlendingTicket(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
		>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
	</ReportModal>;
}

function BatchTicket(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			label={<Translate value='components.reports.salesOrder' />}
			data={props.salesOrders}
			name={'salesOrder'}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		<InputText
			data={props.formulas}
			label={<Translate value='components.reports.batchTicket' />}
			name={'formula'}
			action={event => props.onChangeBatchTicket(event.target.value)}
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : '';
				props.onChangeInput('startDate', value);
			}}
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : '';
				props.onChangeInput('endDate', value);
			}}
		/>
	</ReportModal>
}

function Invoice(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.salesOrder' />}
			data={props.salesOrders}
			name={'salesOrder'}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
	</ReportModal>
}

function LabRequest(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.labRequest' />}
			data={props.labRequests}
			name={'salesOrder'}
			action={(event) => props.onChangeLabRequest(event.target.value)}
		/>
	</ReportModal>
}

function BatchAndColorMatchDuration(props) {

	var formulaTypes = {
		88: constants.base, // <Translate value='components.reports.base' />,
		89: constants.finishedInk, // <Translate value='components.reports.finishedInk' />,
		90: constants.varnish, // <Translate value='components.reports.varnish' />,
		91: constants.other, // <Translate value='components.reports.other' />,
		163: constants.additive, // <Translate value='components.reports.additive' />,
		164: constants.dispersion, // <Translate value='components.reports.dispersion' />,
		165: constants.opv, // <Translate value='components.reports.opv' />,
		166: constants.blendingInk, // <Translate value='components.reports.blendingInk' />,
	};

	var orderTypes = {
		133: constants.labRequest, // <Translate value='components.reports.labRequest' />,
		135: constants.salesOrder, // <Translate value='components.reports.salesOrder' />,
	};

	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
		    data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown label={<Translate value='components.reports.formulaType' />} data={formulaTypes} 
			action={event => props.onChangeInput('formulaTypeLookupId', event.target.value)}
		/>

        <DataDropdown label={<Translate value='components.reports.orderType' />} data={orderTypes} 
			action={event => props.onChangeInput('orderTypeLookupId', event.target.value)}
			value={props.orderTypeLookupId}
		/>

		{props.orderTypeLookupId == '133' &&
		   <InputText
		   type={'SingleSelect'}
		   data={props.labtechs}
		   label={<Translate value='components.reports.matchedBy' />}
		   name={'labtech'}
		   action={event => props.onChangeLabTech(event.value)}
	   />
		}

		<InputText type='CheckBox' label={<Translate value='components.reports.internalBatches' />} 
			action={event => props.onChangeInput('internalBatches', event.target.checked)}
			value={props.internalBatches}
		/>
		<InputText type='CheckBox' label={<Translate value='components.reports.internationalCustomers' />}
			action={event => props.onChangeInput('internationalCustomers', event.target.checked)}
			value={props.internationalCustomers}
		/>	
		
		
	</ReportModal>
}

function BatchVariance(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		
		
	</ReportModal>
}


function LateLossDamageReportForSalesOrder(props) {

	var openOrdersModes = {
		'I': constants.include, // <Translate value='components.reports.include' />,
		'E': constants.exclude, // <Translate value='components.reports.exclude' />,
		'O': constants.only, // <Translate value='components.reports.only' />,
	};
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		
		<InputText
			type={'SingleSelect'}
			data={props.shippers}
			label={<Translate value='components.reports.shipper' />}
			name={'shipper'}
			action={event => props.onChangeShipper(event.value)}
		/>

        <DataDropdown
			label={<Translate value='components.reports.openOrders' />} 
			data={openOrdersModes} 
			action={event => props.onChangeOpenOrders(event.target.value)}
			value={props.openOrders}
		/>
	</ReportModal>
}

function LateLossDamageReportForPurchaseOrder(props) {

	var openOrdersModes = {
		'I': constants.include, // <Translate value='components.reports.include' />,
		'E': constants.exclude, // <Translate value='components.reports.exclude' />,
		'O': constants.only, // <Translate value='components.reports.only' />,
	};
		return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			label={<Translate value='components.reports.from' />}
			type='DateTime' 			
			action={(event) => {
				const startDate = convertTimestampToDate(event._d);
				props.onChangeStartDate(startDate);
			}}
			value={props.startDate}
		/>
		<InputText
			label={<Translate value='components.reports.to' />}
			type='DateTime'
			action={(event) => {
				const endDate = convertTimestampToDate(event._d);
				props.onChangeEndDate(endDate);
			}}
			value={props.endDate}
		/>
		
		<InputText
			type={'SingleSelect'}
			data={props.vendors}
			label={<Translate value='components.reports.vendor' />}
			name={'vendor'}
			action={event => props.onChangeVendor(event.value)}
		/>

        <InputText
			type={'SingleSelect'}
			data={props.components}
			label={<Translate value='components.reports.component' />}
			name={'component'}
			action={event => props.onChangeComponent(event.value)}
		/>

        <DataDropdown
			label={<Translate value='components.reports.openOrders' />} 
			data={openOrdersModes} 
			action={event => props.onChangeOpenOrders( event.target.value)}
			value={props.openOrders}
		/>
	</ReportModal>
}

function SalesOrder(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		<DataDropdown
			required
			label={<Translate value='components.reports.salesOrder' />}
			data={props.salesOrders}
			name={'salesOrder'}
			action={(event) => props.onChangeSalesOrder(event.target.value)}
		/>
	</ReportModal>
}

function VOC(props) {
	return <ReportModal
		visible={props.visible}
		onHide={props.onHide}
		onSubmit={props.onSubmit}
		onChangeReportFormat={props.onChangeReportFormat}
	>
		<InputText type='DateTime' label={<Translate value='components.reports.from' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('startDate', value);
			}}
			value={props.startDate}	
		/>
		<InputText type='DateTime' label={<Translate value='components.reports.to' />}  
			action={event => {
				const value = event._d ? convertTimestampToDate(event._d) : event;
				props.onChangeInput('endDate', value);
			}}
			value={props.endDate}	
		/>
		<InputText
			type={'SingleSelect'}
			data={props.formulas}
			label={<Translate value='components.reports.formula' />}
			name={'formula'}
			action={event => props.onChangeFormula(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.corporates}
			label={<Translate value='components.reports.Corporate' />}
			name={'corporate'}
			action={event => props.onChangeCorporate(event.value)}
		/>
		<InputText
			type={'SingleSelect'}
			data={props.customers}
			label={<Translate value='components.reports.customer' />}
			name={'customer'}
			action={event => props.onChangeCustomer(event.value)}
		/>
		
		
	</ReportModal>
}

let validationFields = {
	customer: "",
	type: "",
	status: "",
	jobId: "",
	colorId: "",
	customerPressId: "",
	startDate: "",
	endDate: "",
	setupMode: "",
	comparisonCriteria: "",
	salesOrder: "",
};

const defaultReportParams = {
	company: "BCMInks",
	logo: "https://bcminks.myinkiq.com//images/companies/1",
	actorId: "",
	dateToUse: "S",
	userType: "",
	allCustomerAccess: "",
	vendor: "",
	shipper: "",
	labtech: "",
	corporate: 0,
	customer: 0,
	orderId: 0,
	jobId: 0,
	location: "A",
	salesRep:"",
	formula: 0,
	component: 0,
	componentNumber: "",
	numberOfCopies: "1",
	batchticket: "",
	salesOrder: "0",
	purchaseOrder: "0",
	startDate: convertTimestampToDate(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)),
	endDate: convertTimestampToDate(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)),
	compareFromDate: "",
	compareToDate: "",
	shipDate: convertTimestampToDate(new Date()),
	consignment: false,
	consignmentBilling: true,
	internalOrders: constants.default_internal_order,
	newSales: "I",
	showProfit: false,
	summary: true,
	showTrendGraph: true,
	lowPh: false,
	highPh: false,
	lowViscosity: false,
	highViscosity: false,
	specOutOfPh: false,
	specOutOfViscosity: false,
	regularBilling: true,
	internalBatches: false,
	internationalCustomers: false,
	formulaTypeLookupId: 0,
	orderTypeLookupId: 0,
	orderType: "S",
	lrType:"",
	priceRangeFrom: 0,
	priceRangeTo: 0,
	priceBasedUpon: "",
	hideDetail: false,
	openOrder: "",
	customerPressId: 0,
	orderFormula: 0,
	type: "J",
	__format: "pdf",
	__asattachment: true,
	__parameterpage: false, // The default params dialog
	dt: 0,
	env: (Config.env != null ? Config.env : "prod"),
	setupMode: "",
	groupBy: "",
	sortBy: "",
	sig: "",
	// limit: "",
};

export default class Report extends Component {

	constructor(props) {
		super(props);
		var date = new Date();
		this.state = {
			customers: [],
			initialCustomers: [],
			corporates: [],
			salesOrders: [],
			creditMemos: [],
			purchaseOrders: [],
			vendors: [],
			shippers: [],
			salesOrderFormulas: [],
			customerFormulas: [],
			customerBlendingInks: [],
			components: [],
			salesReps: [],
			jobs: [],
			colors: [],
			labtechs: [],
			presses: [],
			jobColors: [],
			pressColors: [],
			params: defaultReportParams,
			showModal: false,
			retired: 0,
			errors: resetObject(validationFields),
		};
		this.getJobColors = this.getJobColors.bind(this);
		this.getPressColors = this.getPressColors.bind(this);
		this.onChecked = this.onChecked.bind(this);
		this.getCustomerPresses = this.getCustomerPresses.bind(this);
		this.onChangeState = this.onChangeState.bind(this);
		this.onClickReport = this.onClickReport.bind(this);
	}
	
	getCustomerPresses(userCustomerId) {
		getAPI('customers/' + userCustomerId + '/presses/list').then(presses => {
			this.setState({
				presses: presses['data']
			});
		});
	}

	
	componentDidMount() {
		const loggedInUser = getLoggedInUser();
		const userTypeId = loggedInUser.userTypeId;
		let params = this.state.params;
		let activeCustomers = [];
		let customersList = [];
		let customers = [];
		let corporates = [];
		let initialCustomers = [];
		
		getAPI('customers/list').then(customersTemp => {
			activeCustomers = customersTemp['data'].filter(cust => cust.status == 'Active')
			initialCustomers = activeCustomers; // customersTemp['data'];
			customers = activeCustomers
				//.filter(customer => customer.status == 'Active')
				.map(customer => ({value: customer.id, label: customer.name}));
			// console.log("customers retrieved: ", customers);
			corporates = customersTemp['data'].filter(cust => cust.bCorporate != null && cust.bCorporate > 0).map(customer => ({value: customer.id, label: customer.name}));
		}).then(() => {
			// console.log(customers)
			switch(userTypeId) {
				case constants.sales_type_id:
					if(!loggedInUser.allCustomerAccessFlag){
						getAPI('users/' + loggedInUser.id + '/customers').then(salesCustomers => {
							salesCustomers.data.forEach(cust => {
								const custRow = customers.filter(row => row.value == cust.customerId)[0];
								if(custRow)
									customersList.push(custRow);
							});
							// console.log("customersList in case sales person is logged in : ", customersList);
						});
					} else {customersList = customers}
					break;
					
				case constants.customer_type_id:
					// const custRow = customers.filter(row => row.value == loggedInUser.customerId)[0];
					//if(custRow)
					//	customersList.push(custRow);
					customersList = customers;
					
					params.customer = loggedInUser.userCustomerIDs; // .join(","); // loggedInUser.customerId;
					// console.log("customersList in case customer is logged in : ", customersList, ", loggedInUser.customerId: ", loggedInUser.customerId, ", loggedInUser.userCustomerIDs: ", loggedInUser.userCustomerIDs);
					break;
				
				default:
					// console.log("customersList in case anyone else isloggedin  : ", customersList);
					customersList = customers;
					break
			}
		}).then(() => {
			// console.log(customersList)
			this.setState({
				customers: customersList,
				params: params,
				initialCustomers: initialCustomers,
				corporates: corporates,
			});
		});
		getAPI('salesorders/list').then(orders => this.setState({
			salesOrders: orders.data.filter(order => order.salesOrderType == 'Sales Order'),
			creditMemos: orders.data.filter(order => order.salesOrderType == 'Credit Memo'),
			labRequests: orders.data.filter(order => order.salesOrderType == 'Lab Request'),
		}));
		getAPI('purchaseorders/list').then(pOrders => this.setState({
			purchaseOrders: pOrders.data
				.map(pOrder => ({value: pOrder.id, label: pOrder.id + " " + pOrder.vendorName})),
		}));
		getAPI('vendors/list').then(vendor => this.setState({
			vendors: vendor.data
				.map(vendor => ({value: vendor.id, label: vendor.name})),
		}));
		getAPI('shippers/list').then(shipper => this.setState({
			shippers: shipper.data
				.map(shipper => ({value: shipper.id, label: shipper.name})),
		}));
		getAPI('components/list').then(components => {
			this.setState({
				components: components.data.map(component => ({value: component.id, label: component.name + " (" + component.component + ")"}))
			});
		});
		getAPI('jobs/list').then(jobs => {
			this.setState({
				jobs: jobs['data']
			});
		});
		
		getAPI('colors/list').then(colors => {
			this.setState({
				colors: colors['data']
			})
		});


		getAPI('users/labtechs').then(labtech => this.setState({
			labtechs: labtech.data
				.map(labtech => ({value: labtech.id, label: labtech.name})),
		}));

		
		
		let userCustomerId = 0;
		// console.log("loggedInUser.userType: ", loggedInUser);
		if(loggedInUser.userTypeId == constants.customer_type_id) {
			this.updateCustomerFormulas(loggedInUser.customerId);
			this.updateCustomerSalesReps(loggedInUser.customerId);
			
			userCustomerId = loggedInUser.customerId;
		}
		else {
			getAPI('formulas/search?cid=0&limit=99000&start=0&search=').then(formulas => {
			// getAPI('formulas/vendors/list').then(formulas => {
				const customerFormulas = formulas.data.filter(row => row.formulaStatusName == 'Active')
				.filter(noBlend=> noBlend.formulaType != "Blending Ink")
				.map(formula => ({
					value: formula.id, 
					label: formula.name + ( formula.formulaNumber ? " (" + formula.formulaNumber + ")" : "" )
				}));
				this.setState({
					customerFormulas: customerFormulas,
					salesOrderFormulas: customerFormulas,
				});
			 });
			 
			getAPI('users/salesreps').then(salesReps => {
				let salesRepsList = salesReps['data'].map(salesRep => ({value: salesRep.id, label: salesRep.name}));
				this.setState({
					salesReps: salesRepsList
				});
			});
		}
		
		this.getCustomerPresses(userCustomerId);
		
		getAPI('users/salesreps').then(salesReps => {
			let salesRepsList = salesReps['data'].map(salesRep => ({value: salesRep.id, label: salesRep.name}));
			
			switch(userTypeId) {
				case constants.sales_type_id:
					// allCustomerAccessFlag being true means this is a R&D & Sales person
					if(!loggedInUser.allCustomerAccessFlag) {
						let salesRep = [{value: 0, label: ""}];
						//remove all but the current sales user
						salesRepsList.forEach(function(rep) {
							if (rep.value === loggedInUser.id) {
								salesRep[0] = rep;
							}
						});
						// console.log("tried to filter " )
						params.salesRep = salesRep[0].value;
						this.setState({
							salesReps: salesRep,
							params: params,
						})
					}
					break;
				default:
					this.setState({
						salesReps: salesRepsList
					});
					break;
				}
		});		
	}
	
	getJobColors(jobId) {
		let jobColors = [];
		getAPI('jobs/' + jobId + '/colors').then(resColors => {
			this.setState({
				jobColors: resColors['data'].map(obj => {
					let colorName = obj.name + (obj.formulaNumber ? " (" + obj.formulaNumber + ")" : "");
					// let id = obj.colorId > 0 ? obj.colorId + "_C" : obj.formulaId + "_F";
					let id = obj.colorId > 0 ? obj.id + "_C" : obj.id + "_F";
					return {id: id, chkInclude: true, name: colorName, chkCompareDE: true, chkCompareAbsDensity: false, colorId: obj.colorId, formulaId: obj.formulaId};
				}),
			});
		});
	}
	
	getPressColors(customerId, pressId) {
		let pressColors = [];
		getAPI('customers/' + customerId + '/presses/' + pressId + '/colors').then(resColors => {
			this.setState({
				pressColors: resColors['data'].map(obj => {
					// let id = obj.id;
					let id = obj.type == "C" ? obj.id + "_C" : obj.id + "_F";
					let colorName = obj.name + (obj.formulaNumber ? " (" + obj.formulaNumber + ")" : "");
					return {id: id, chkInclude: true, name: colorName, chkCompareDE: true, chkCompareAbsDensity: false};
				}),
			});
		});
	}
	
	onClickReport(reportName) {
		// console.log("onClickReport() - reportName: ", reportName);
		// Samee - Whenever a report is selected, the report form params should get reset
		let params = defaultReportParams; // this.state.params;
		
		// And so should the validation fields
		validationFields = {
			customer: "",
			type: "",
			status: "",
			jobId: "",
			colorId: "",
			customerPressId: "",
			startDate: "",
			endDate: "",
			setupMode: "",
			comparisonCriteria: "",
			salesOrder: "",
		};
		switch(reportName) {
			case 'Historical':
				validationFields = {
					customer: "",
					type: "required",
					status: "",
					jobId: "required",
					colorId: "",
					customerPressId: "",
					startDate: "required",
					endDate: "required",
					setupMode: "",
					comparisonCriteria: "",
				};
				break;

			case 'BatchTicket':
			case 'TrendingAgingReport':
				params.startDate = '';
				params.endDate = '';
				params.internalOrders = 'I';
				if(reportName == 'BatchTicket')
					validationFields.salesOrder = "required,positive";
				break;
			
			// 
			case 'SalesOrderLRShipment':
				params.internalOrders = constants.default_internal_order;
				break;
				
			case 'LbsSold':
				params.internalOrders = "E";
				break;
				
			case 'BatchAndColorMatchDuration':
				params.orderTypeLookupId = 135;
				break;
				
			case 'BCM_Invoice':
			case 'BCM_Lab_Request':
			case 'BCM_Sales_Order':
				validationFields.salesOrder = "required,positive";
				break;
				
			default:
				break;
			
		}
		
		// console.log("params.startDate: ", params.startDate, ", params.endDate: ", params.endDate, ", params.internalOrders: ", params.internalOrders);
		
		this.setState({showModal: reportName, params: params});
	}
	
	onChangeState(name, value) {
		//console.log("onChangeState() - name: ", name, ", value: ", value);
		this.setState({
			[name]: value,
		});
	}
	
	onChangeParam(paramName, value) {
		let params = JSON.parse(JSON.stringify(this.state.params));
		let customers = this.state.initialCustomers;
		// console.log("Report::onChangeParam() - before - paramName: ", paramName, ", value: ", value);
		switch(paramName) {
			case 'startDate':
			case 'endDate':
			case 'compareFromDate':
			case 'compareToDate':
				if(value.value == 0 && value.label == '')
					value = '';
				break;
			case 'corporate':
				// console.log("selected corporate value: ", value, ", initialCustomers: ", customers);
				if(value != null && value > 0)
					customers = customers.filter(customer => customer.parentCorporateId == value);

				customers = customers.map(customer => ({value: customer.id, label: customer.name}));
				
				break;
		}

		params[paramName] = value;		
		// console.log("Report::onChangeParam() - after - value: ", value, ", params: ", params);
		
		this.setState({
			params: params,
			customers: customers,
		},function () {
			switch (paramName) {
				case "customer": 
					this.updateCustomerFormulas(value);
					this.updateCustomerSalesReps(value);
					this.getCustomerPresses(value);
					break;
				
				default:
					break;
			
			}
			
		});
		
	}

	onChangeInput(paramName, value) {
		// console.log("Report::onChangeInput()1 - paramName: ", paramName, ", value: ", value);
		const loggedInUser = getLoggedInUser();
		switch(paramName) {
			
			case 'jobId':
				this.getJobColors(value);
				break;
				
			case 'customerPressId':
				this.getPressColors(loggedInUser.customerId, value);
				break;
			
			case "type":
				switch(value) {
					case 'J':
						validationFields.jobId = "required";
						validationFields.colorId = "";
						validationFields.customerPressId = "";
						validationFields.comparisonCriteria = "";
						break;
					
					case 'I':
						validationFields.jobId = "";
						validationFields.colorId = "required";
						validationFields.customerPressId = "";
						validationFields.comparisonCriteria = "required";
						break;
					
					case 'P':
						validationFields.jobId = "";
						validationFields.colorId = "";
						validationFields.customerPressId = "required";
						validationFields.comparisonCriteria = "";
						break;
				}
				break;
			
			case 'startDate':
			case 'endDate':
			case 'compareFromDate':
			case 'compareToDate':
				if(value.value == 0 && value.label == '')
					value = '';
				break;
		}
		
		let params = JSON.parse(JSON.stringify(this.state.params));
		params[paramName] = value;
		this.setState({params: params});
	}

	onChangeBooleanParam(paramName) {
		let params = JSON.parse(JSON.stringify(this.state.params));
		params[paramName] = params[paramName] == 'true' ? 'false' : 'true';
		this.setState({params: params});
	}
	
	onChecked(event, name, field) {
		const fieldToUncheck = field == 'chkCompareDE' ? 'chkCompareAbsDensity': 'chkCompareDE';
		// console.log("onChecked() - event.target.value: ", event.target.value, ", event.target.checked: ", event.target.checked, ", name: ", name, ", field: ", field, ", fieldToUncheck: ", fieldToUncheck);
		const value = event.target.value;
		const checked = event.target.checked;
		let rows = this.state[name];
		const rowIndex = rows.findIndex((row) => {
			return row.id == value;
		});
		
		if(rowIndex > -1) {
			let row = rows[rowIndex];

			if(field == 'chkInclude') {
				row[field] = checked;
				//if(!checked)
				row['chkCompareDE'] = checked;
				row['chkCompareAbsDensity'] = false;
			} else {
				if(row.chkInclude) {
					row[field] = checked;
					if(checked) {
						if(field == 'chkCompareDE')
							row['chkCompareAbsDensity'] = false;
						else if(field == 'chkCompareAbsDensity')
							row['chkCompareDE'] = false;
					}
				}
			}
			rows[rowIndex] = row;
			
			this.setState({
				[name]: rows
			});
		}
	}

	updateCustomerFormulas(customerId) {
		let activeFormulas = [];
		getAPI('customers/' + customerId + '/formulas').then(formulas => {
			activeFormulas = formulas.data.filter(form => form.status != "Inactive").filter(noBlend => noBlend.formulaType != "Blending Ink");
			this.setState({
				customerFormulas: activeFormulas.map(formula => ({
					value: formula.formulaId, 
					label: (formula.customerFormulaName ? formula.customerFormulaName : formula.name) + ( formula.formula ? " (" + formula.formula + ")" : "" ),
				})),
			});
		})
	}

	clearCustomerFormulas() {
		this.setState({
			customerFormulas: [],
		});
	}

	updateCustomerBlendingInks(customerId) {
		getAPI('customers/' + customerId + '/formulas').then(formulas => {
			var blendingInks = [];
			formulas.data.forEach(function(formula) {
				if (formula.formulaType === "Blending Ink") {
					blendingInks.push(formula);					
				}	
			});
			this.setState({
				customerBlendingInks: blendingInks.map(formula => ({value: formula.formulaId, label: formula.formula})),
			});
		})
	}

	clearCustomerBlendingInks() {
		this.setState({
			customerBlendingInks: [],
		});
	}
	
	updateSalesOrderFormulas(salesOrder) {
		getAPI("salesorders/" + salesOrder + "/formulas/list").then(formulas => {
			this.setState({
				salesOrderFormulas: formulas.data.map(formula => ({value: formula.formulaId, label: formula.formula})),
			});
		});
	}

	clearSalesOrderFomulas() {
		this.setState({
			salesOrderFormulas: [],
		});
	}
	
	updateCustomerSalesReps(customerId) {
		const loggedInUser = getLoggedInUser();
		const userTypeId = loggedInUser.userTypeId;
		
		let params = this.state.params;
		
		getAPI('customers/' + customerId + '/salesreps').then(salesReps => {
			let salesRepsList = salesReps.data.map(salesRep => ({value: salesRep.actorId, label: salesRep.displayName}))
			
			switch(userTypeId) {
				case constants.sales_type_id:
					let salesRep = [{value: 0, label: "Error: You Are Not The Sales Rep"}];
					//remove all but the current sales user
					salesRepsList.forEach(function(rep) {
						if (rep.value === loggedInUser.id) {
							salesRep[0] = rep;
						}
					});
					// console.log("tried to filter " )
					if (salesRep[0].value != 0) {
						params.salesRep = salesRep[0].value;
						this.setState({
							salesReps: salesRep,
							params: params,
						})
					} else {
						this.setState({
							salesReps: salesRep,
							params: params,
						})
					}
					break;
				default:
					this.setState({
						salesReps: salesRepsList
					});
					break;
				}
		});		
	}

	clearCustomerSalesReps() {
		this.setState({
			salesReps: [],
		});
	}

	onSubmit(reportName) {
		let params = this.state.params;
		// console.log("onSubmit() - reportName: ", reportName, ", params: ", params, ", validationFields: ", validationFields);
		let validation = validateForm(params, validationFields);
		// console.log("validation.errors: ", validation.errors);
		if(!validation.result)
		{
			
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors,
			});
		}
		else {
			
			if(reportName == 'BCM_Bucket_Label'){
				openBirtReport('BCM_Bucket_Label', {
					customer: this.state.params.customer,
					formula: this.state.params.formula,
					orderFormula: this.state.params.orderFormula,
					numberOfCopies: 1,
				});
			}
			if(reportName == 'BCM_Lbs_Sold'){
				if(this.state.params.summary === true) {
					// reportName = 'BCM_Lbs_Sold_Summary';
				}
				
				// Comparison Reports
				if(this.state.params.compareFromDate != null && this.state.params.compareToDate != null && this.state.params.compareFromDate != '' && this.state.params.compareToDate != '') {
					// Comparison Report
					reportName = (this.state.params.showProfit) ? "BCM_Lbs_Profit_Comparison" : "BCM_Lbs_Comparison";
					if(this.state.params.groupBy != "") {
						reportName = "BCM_TopSalesComparison_Report";
					}
				} else {
					reportName = (this.state.params.summary) ? "BCM_Lbs_Shipped_Summary" : "BCM_Lbs_Shipped_Corporates";
					if(this.state.params.groupBy != "") {
						reportName = "BCM_TopSales_Report";
					}
				}
				
				if(this.state.params.__format == 'xls')	 {
					params.__format = 'xlsx';
					params.__emitterid = 'uk.co.spudsoft.birt.emitters.excel.XlsxEmitter';
					params.__fittopage = true;
					// params.__svg = true;
					// params.__masterpage = true;
					// params.__rtl = false;
					// params.__cubememsize = 10; 
					// params.__dpi = 120; 
					this.setState({params: params});
				} else {
					delete(params.__emitterid);
					delete(params.__masterpage);
					delete(params.__rtl);
					delete(params.__cubememsize);
					delete(params.__dpi);
					this.setState({params: params});
				}
			}
			
			
			if(reportName == 'BCM_SalesOrder_LR_Shipment'){
				if(this.state.params.compareFromDate != null && this.state.params.compareToDate != null && this.state.params.compareFromDate != '' && this.state.params.compareToDate != '') {
					// Comparison Report
					reportName = "BCM_SalesOrder_LR_Shipment_Comparison";
				} else if(this.state.params.summary) {
					reportName = 'BCM_SalesOrder_LR_Shipment_Summary';
				}
			}
			// console.log("this.state.params.summary: ", this.state.params.summary, ", reportName: ", reportName);
			
			// if(reportName == 'BCM_Batch_Ticket'){
				if(this.state.params.__format == 'xlsx')	 {
					params.__emitterid = 'uk.co.spudsoft.birt.emitters.excel.XlsxEmitter';
					params.__masterpage = true;
					params.__rtl = false;
					params.__cubememsize = 10;
					params.__dpi = 120;
					// params.__fittopage = true;
					this.setState({params: params});
				} else {
					delete(params.__emitterid);
					delete(params.__masterpage);
					delete(params.__rtl);
					delete(params.__cubememsize);
					delete(params.__dpi);
					this.setState({params: params});
				}
			// }
			
			
			if(reportName == 'BCM_Historical') {
				let colorIds = "";
				let comparisonCriteria = "";
				if(this.state.params.type == 'J' || this.state.params.type == 'P') {
					let colorRows = this.state.params.type == 'J' ? this.state.jobColors : (this.state.params.type == 'P' ? this.state.pressColors : []);
					
					colorRows.filter(row => row.chkInclude == true).map(obj => {
						colorIds += (colorIds ? "," : "") + obj.id;
						comparisonCriteria += (comparisonCriteria ? "," : "") + (obj.chkCompareDE ? "0" : "1");
					});
					// console.log("this.state.params.type: ", this.state.params.type, ", colorRows: ", colorRows, ", colorIds: ", colorIds, ", comparisonCriteria: ", comparisonCriteria);
					params.tmpColorId = colorIds;
					params.tmpComparisonCriteria = comparisonCriteria;
				} else {
					delete params.tmpColorId;
					delete params.tmpComparisonCriteria;
				}
			}
			
			// console.log("reportName: ", reportName);
			if(reportName == 'BCM_VOC'){
				if(this.state.params.__format == 'xls')	 {
					params.__format = 'xlsx';
					params.__emitterid = 'uk.co.spudsoft.birt.emitters.excel.XlsxEmitter';
					params.__fittopage = true;
					this.setState({params: params});
				} else {
					delete(params.__emitterid);
					delete(params.__masterpage);
					delete(params.__rtl);
					delete(params.__cubememsize);
					delete(params.__dpi);
					this.setState({params: params});
				}
			}

			/*
			//	It's better to play around with the params within the openBirtReport() function instead 
			//	That way, the date values aren't screwed up after the View Report button is clicked
			//	(as was happening before)
			
			if(Config.locale === 'ES_MX') {
				console.log("this.state.params.startDate: ", this.state.params.startDate, ", this.state.params.endDate: ", this.state.params.endDate);
				
				if(this.state.params.startDate !== '') {
					this.state.params.startDate = reverseDateForReport(this.state.params.startDate);
				}				
				if(this.state.params.endDate !== '') {
					this.state.params.endDate = reverseDateForReport(this.state.params.endDate);
				}
				if(this.state.params.shipDate !== '') {
					this.state.params.shipDate = reverseDateForReport(this.state.params.shipDate);
				}
				if(this.state.params.compareFromDate !== '') {
					this.state.params.compareFromDate = reverseDateForReport(this.state.params.compareFromDate);
				}
				if(this.state.params.compareToDate !== '') {
					this.state.params.compareToDate = reverseDateForReport(this.state.params.compareToDate);
				}
				
			}
			if(this.state.params.compareFromDate === '') {
				delete this.state.params.compareFromDate;
			}
			if(this.state.params.compareToDate === '') {
				delete this.state.params.compareToDate;
			}
			
			if (reportName == 'BCM_Purchase_Order') {
				openBirtReport('BCM_Purchase_Order',
					{purchaseOrder: this.state.params.purchaseOrder,}
				)
				return;
			}
			*/
			const loggedInUser = getLoggedInUser();
			params.userType = loggedInUser.userType
			params.allCustomerAccess = loggedInUser.allCustomerAccessFlag
			// console.log("report params: ", params);
			openBirtReport(reportName, params);
		}
		
		
	}

	render() {
		const loggedInUser = getLoggedInUser();
		const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
		let salesOrders = this.state.salesOrders &&
			mapArrayToObject(this.state.salesOrders.filter(obj =>
				obj.customerId == this.state.params.customer),
			'id', 'id');
		let labRequests = this.state.labRequests &&
			mapArrayToObject(this.state.labRequests.filter(obj =>
				obj.customerId == this.state.params.customer),
			'id', 'id');
		let creditMemos = this.state.creditMemos &&
			mapArrayToObject(this.state.creditMemos.filter(obj =>
				obj.customerId == this.state.params.customer),
			'id', 'id');
		
		let jobs = this.state.jobs;
		let colors = this.state.colors;
		let presses = this.state.presses;

		let customerId = this.state.params.customer;
		if(loggedInUser.customerId > 0)
			customerId = loggedInUser.customerId;
		
		if(customerId && customerId > 0) {
			jobs = jobs.filter(job => job.customerId == customerId);
			colors = colors.filter(color => color.customerId == customerId);
			presses = presses.filter(press => press.customerId == customerId);
		}
		// console.log("this.state.retired: ", this.state.retired);
		if(this.state.retired >= 0) {
			jobs = jobs.filter(job => job.retired == this.state.retired);
			colors = colors.filter(color => color.retired == this.state.retired);
		}
		
		jobs = jobs.map(job => ({
			value: job.jobId, 
			label: "Job Name: " + job.jobName + "   Desc: " + job.jobDescription
		}));
		
		colors = colors.map(color => ({
			value: color.colorId > 0 ? color.colorId + "_C" : color.formulaId + "_F", 
			label: (color.formulaName ? color.formulaName : color.colorName) + (color.formulaNumber ? " (" + color.formulaNumber + ")" : "")
		}));
		
		presses = presses.map(press => ({value: press.id, label: press.pressName}));
		
		return <div style={{marginTop: '1em'}}>
			<Row>
			{!bCustomerLoggedIn &&
				<Col md={6}>
					<Panel>
						<Panel.Heading>
							<Panel.Title toggle>
							<Translate value='components.reports.accountingReports' />
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse>
							<ListGroup>
								{loggedInUser.userReports[constants.aging_report_id] &&
								 <ListGroupItem
									onClick={() => {
										this.onClickReport('AgingReport');
										// this.setState({showModal: 'BatchTicket', params: defaultReportParams});
									}}
								>
									<Translate value='components.reports.agingReport' />
								</ListGroupItem>}
								{loggedInUser.userReports[constants.trending_aging_report_id] &&
								 <ListGroupItem
									onClick={() => {
										this.onClickReport('TrendingAgingReport');
										// this.setState({showModal: 'BatchTicket', params: defaultReportParams});
									}}
								>
									<Translate value='components.reports.trendingAgingReport' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.credit_memo_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'CreditMemo', params: defaultReportParams})}
								>
									<Translate value='components.reports.creditMemo' />
								</ListGroupItem>}
								{loggedInUser.userReports[constants.inventory_transactions_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'InventoryTransactions', params: defaultReportParams})}
								>
									<Translate value='components.reports.inventoryTransactionsReport' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.inventory_variance_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'InventoryVariance', params: defaultReportParams})}
								>
									<Translate value='components.reports.inventoryVarianceReport' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.invoice_summary_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'InvoiceSummary', params: defaultReportParams})}
								>
									<Translate value='components.reports.invoiceSummaryReport' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.order_listing_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'OrderListing', params: defaultReportParams})}
								>
									<Translate value='components.reports.orderListingReport' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.purchase_order_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'PurchaseOrder', params: defaultReportParams})}
								>
									<Translate value='components.reports.purchaseOrder' />
								</ListGroupItem>}
							</ListGroup>
						</Panel.Collapse>
					</Panel>
					<Panel>
						<Panel.Heading>
							<Panel.Title toggle>
							<Translate value='components.reports.shippingReports' />
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse>
							<ListGroup>
								{loggedInUser.userReports[constants.bill_of_lading_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'BillOfLading', params: defaultReportParams})}
								>
									<Translate value='components.reports.billOfLading' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.bucket_label_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'BucketLabel', params: defaultReportParams})}
								>
									<Translate value='components.reports.bucketLabel' />
								</ListGroupItem>}

								{loggedInUser.userReports[constants.late_loss_damage_for_sales_order_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'LateLossDamageReportForSalesOrder', params: defaultReportParams})}
								>
									<Translate value='components.reports.lateLossDamageReportForSalesOrder' />
								</ListGroupItem>}

								{loggedInUser.userReports[constants.late_loss_damage_for_purchase_order_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'LateLossDamageReportForPurchaseOrder', params: defaultReportParams})}
								>
									<Translate value='components.reports.lateLossDamageReportForPurchaseOrder' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.packing_slip_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'PackingSlip', params: defaultReportParams})}
								>
									<Translate value='components.reports.packingSlip' />
								</ListGroupItem>}

								
							</ListGroup>
						</Panel.Collapse>
					</Panel>
					
					<Panel>
						<Panel.Heading>
							<Panel.Title toggle>
							<Translate value='components.reports.formulaReports' />
							</Panel.Title>
							
						</Panel.Heading>
						<Panel.Collapse>
							<ListGroup>

							{loggedInUser.userReports[constants.formula_change_reason_report_id] &&
							<ListGroupItem
									onClick={() => this.setState({ showModal: 'FormulaChangeReason', params: defaultReportParams })}
								>
									<Translate value='components.reports.formulaChangeReasonReport' />
							</ListGroupItem>}
								
							{loggedInUser.userReports[constants.contains_components_report_id] &&
								<ListGroupItem
									onClick={() => this.setState({showModal: 'ContainsComponents', params: defaultReportParams})}
								>
									<Translate value='components.reports.containsComponentsReport' />
								</ListGroupItem>}
                            
							{loggedInUser.userReports[constants.batch_variance_id] &&
							    <ListGroupItem
									onClick={() => this.setState({ showModal: 'BatchVariance', params: defaultReportParams })}
							    >
									<Translate value='components.reports.batchVarianceReport' />
							    </ListGroupItem>}

							{loggedInUser.userReports[constants.formula_cost_price_change_report_id] &&
							    <ListGroupItem
									onClick={() => this.setState({ showModal: 'FormulaCostPriceChange', params: defaultReportParams })}
								>
									<Translate value='components.reports.formulaCostPriceChangeReport' />
							    </ListGroupItem>}

							{loggedInUser.userReports[constants.component_cost_price_change_report_id] &&
							    <ListGroupItem
									onClick={() => this.setState({ showModal: 'ComponentCostPriceChange', params: defaultReportParams })}
								>
									<Translate value='components.reports.componentCostPriceChangeReport' />
							    </ListGroupItem>}	
								
							{loggedInUser.userReports[constants.formula_cost_report_id] &&
								<ListGroupItem
									onClick={() => this.setState({showModal: 'FormulaCost', params: defaultReportParams})}
								>
									<Translate value='components.reports.formulaCostReport' />
								</ListGroupItem>}
							{loggedInUser.userReports[constants.top_component_report_id] &&
								 <ListGroupItem
										onClick={() => this.setState({showModal: 'TopComponent', params: defaultReportParams})}
									>
									<Translate value={'components.reports.topComponent'} />
								</ListGroupItem>}
							</ListGroup>
						</Panel.Collapse>
					</Panel>					
				</Col> 
				}
				<Col md={6}>
					<Panel>
						<Panel.Heading>
							<Panel.Title toggle>
							<Translate value='components.reports.customerReports' />
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse>
							<ListGroup>
								{loggedInUser.userReports[constants.blending_ticket_report_id] &&
								 <ListGroupItem
										onClick={() => this.setState({showModal: 'BlendingTicket', params: defaultReportParams})}
									>
									<Translate value='components.reports.blendingTicket' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.historical_report_id] &&
								 <ListGroupItem
										onClick = {() => this.onClickReport('Historical')}
									>
									<Translate value='components.reports.historicalReports' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.customer_lbs_sold_report_id] &&
								 <ListGroupItem
										onClick={() => {
											// this.setState({showModal: 'LbsSold', params: defaultReportParams});
											this.onClickReport('LbsSold');
										}}
									>
									<Translate value={'components.reports.' + Config.weightSymbol + 'sSoldReports'} />
								</ListGroupItem>}

								{loggedInUser.userReports[constants.color_match_report_id] &&
								 <ListGroupItem
								        onClick={() => this.setState({ showModal: 'ColorMatch'})}
							        > 
								    <Translate value='components.reports.colorMatchReport' />
						        </ListGroupItem>}

								{loggedInUser.userReports[constants.call_report_summary_id] &&
								 <ListGroupItem
								        onClick={() => this.setState({ showModal: 'CallReportSummary'})}
							        > 
								    <Translate value='components.reports.callReportSummary' />
						        </ListGroupItem>}
								
								{loggedInUser.userReports[constants.voc_report_id] &&
							    <ListGroupItem
									onClick={() => this.setState({ showModal: 'VOC' })}
								>
									<Translate value='components.reports.vocReport' />
							    </ListGroupItem>}
								
								{loggedInUser.userReports[constants.salesorder_lr_shipment_report_id] &&
								 <ListGroupItem
										onClick={() => {
											// this.setState({showModal: 'SalesOrderLRShipment', params: defaultReportParams});
											this.onClickReport('SalesOrderLRShipment');
										}}
									>
									<Translate value={'components.reports.salesOrderLRShipment'} />
								</ListGroupItem>}

							</ListGroup>
						</Panel.Collapse>
					</Panel>
					{!bCustomerLoggedIn &&
					<Panel>
						<Panel.Heading>
							<Panel.Title toggle>
							<Translate value='components.reports.salesOrderReports' />
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse>
							<ListGroup>
								
								{loggedInUser.userReports[constants.batch_ticket_report_id] &&
								 <ListGroupItem
									onClick={() => {
										this.onClickReport('BatchTicket');
										// this.setState({showModal: 'BatchTicket', params: defaultReportParams});
									}}
								>
									<Translate value='components.reports.batchTicket' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.invoice_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'Invoice', params: defaultReportParams})}
								>
									<Translate value='components.reports.invoice' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.lab_request_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'LabRequest', params: defaultReportParams})}
								>
									<Translate value='components.reports.labRequest' />
								</ListGroupItem>}

								{loggedInUser.userReports[constants.batch_and_color_match_duration_report_id] &&
								 <ListGroupItem
									onClick={() => {
										this.onClickReport('BatchAndColorMatchDuration');
										// this.setState({showModal: 'BatchAndColorMatchDuration', params: defaultReportParams});
									}}
								>
									<Translate value='components.reports.batchAndColorMatchDurationReport' />
								</ListGroupItem>}

								
								{loggedInUser.userReports[constants.sales_order_report_id] &&
								 <ListGroupItem
									onClick={() => this.setState({showModal: 'SalesOrder', params: defaultReportParams})}
								>
									<Translate value='components.reports.salesOrder' />
								</ListGroupItem>}
								
								{loggedInUser.userReports[constants.ph_viscosity_report_id] &&
								 <ListGroupItem
									onClick={() => {
										// this.onClickReport('PhAndViscosity');
										this.setState({showModal: 'PhAndViscosity', params: defaultReportParams});
									}}
								>
									<Translate value='components.reports.phViscosityReport' />
								</ListGroupItem>}
							</ListGroup>
						</Panel.Collapse>
					</Panel>
					}
				</Col>
			</Row>
			<AgingReport
				visible={this.state.showModal === 'AgingReport'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Aging')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
			/>
			<TrendingAgingReport
				visible={this.state.showModal === 'TrendingAgingReport'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Trending_Aging')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				showTrendGraph={this.state.params.showTrendGraph}
				internalOrders={this.state.params.internalOrders}
				internationalCustomers={this.state.params.internationalCustomers}
			/>
			<CreditMemo
				visible={this.state.showModal === 'CreditMemo'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Credit_Memo')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				creditMemos={creditMemos}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<InventoryTransactions
				visible={this.state.showModal === 'InventoryTransactions'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Inventory_Transaction')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				onToggleHideDetails={() => this.onChangeBooleanParam('hideDetail')}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeComponentNumber={(componentNumber) => this.onChangeParam('componentNumber', componentNumber)}
			/>
			<InventoryVariance
				visible={this.state.showModal === 'InventoryVariance'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Inventory_Variance')}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<InvoiceSummary
				visible={this.state.showModal === 'InvoiceSummary'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Invoice_Summary')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<OrderListing
				visible={this.state.showModal === 'OrderListing'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Order_Listings')}
				onChangeShipDate={(shipDate) => this.onChangeParam('shipDate', shipDate)}
				shipDate={this.state.params.shipDate}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<PurchaseOrder
				visible={this.state.showModal === 'PurchaseOrder'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Purchase_Order')}
				purchaseOrders={this.state.purchaseOrders}
				onChangePurchaseOrder={(purchaseOrder) => this.onChangeParam('purchaseOrder', purchaseOrder)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BillOfLading
				visible={this.state.showModal === 'BillOfLading'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Bill_Of_Lading')}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BucketLabel
				visible={this.state.showModal === 'BucketLabel'}
				onHide={() => {
					this.clearSalesOrderFomulas()
					this.setState({showModal: false})
				}}
				onSubmit={() => this.onSubmit('BCM_Bucket_Label')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				salesOrders={salesOrders}
				onChangeSalesOrder={salesOrder => {
					this.onChangeParam('salesOrder', salesOrder);
					this.updateSalesOrderFormulas(salesOrder);
				}}
				formulas={this.state.salesOrderFormulas}
				onChangeFormula={formula => this.onChangeParam('orderFormula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<PackingSlip
				visible={this.state.showModal === 'PackingSlip'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Packing_Slip')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				salesOrders={salesOrders}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<ContainsComponents
				visible={this.state.showModal === 'ContainsComponents'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Formulas_Containing_Component')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				components={this.state.components}
				onChangeComponent={component => this.onChangeParam('component', component)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<FormulaChangeReason
				visible={this.state.showModal === 'FormulaChangeReason'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Formula_Change')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				formulas={this.state.customerFormulas}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<ComponentCostPriceChange
				visible={this.state.showModal === 'ComponentCostPriceChange'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Component_Cost_Price_Change')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				components={this.state.components}
				vendors={this.state.vendors}
				onChangeVendor={ vendor=> this.onChangeParam('vendor', vendor)}
				onChangeComponent={component => this.onChangeParam('component', component)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<FormulaCostPriceChange
				visible={this.state.showModal === 'FormulaCostPriceChange'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Formula_Cost_Price_Change')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				formulas={this.state.customerFormulas}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				
			/>
			<FormulaCost
				visible={this.state.showModal === 'FormulaCost'}
				onHide={() => {
					this.clearCustomerFormulas()
					this.setState({showModal: false})
				}}
				onSubmit={() => this.onSubmit('BCM_Formula_Cost_Sheet')}
				customers={this.state.customers}
				onChangeCustomer={customer => {
					this.onChangeParam('customer', customer);
					this.updateCustomerFormulas(customer);
				}}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				formulas={this.state.customerFormulas}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BlendingTicket
				visible={this.state.showModal === 'BlendingTicket'}
				onHide={() => {
					this.clearCustomerBlendingInks()
					this.setState({showModal: false})
				}}
				onSubmit={() => this.onSubmit('BCM_Blending_Ticket')}
				customers={this.state.customers}
				onChangeCustomer={customer => {
					this.onChangeParam('customer', customer);
					this.updateCustomerBlendingInks(customer);
				}}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				formulas={this.state.customerBlendingInks}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BatchTicket
				visible={this.state.showModal === 'BatchTicket'}
				onHide={() => {
					this.clearCustomerFormulas()
					this.setState({showModal: false})
				}}
				onSubmit={() => this.onSubmit('BCM_Batch_Ticket')}
				customers={this.state.customers}
				salesOrders={salesOrders}
				onChangeCustomer={customer => {
					this.onChangeParam('customer', customer);
					this.updateCustomerFormulas(customer);
				}}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				formulas={this.state.customerFormulas}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeBatchTicket={batchticket => this.onChangeParam('batchticket', batchticket)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
			/>
			<Invoice
				visible={this.state.showModal === 'Invoice'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Invoice')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				salesOrders={salesOrders}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<LabRequest
				visible={this.state.showModal === 'LabRequest'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Lab_Request')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				labRequests={labRequests}
				onChangeLabRequest={LabRequest => this.onChangeParam('salesOrder', LabRequest)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BatchAndColorMatchDuration
				visible={this.state.showModal === 'BatchAndColorMatchDuration'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Duration')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				formulas={this.state.customerFormulas}
				customers={this.state.customers}
				labtechs={this.state.labtechs}
				formulaTypes={this.state.formulaTypes}
				orderTypeLookupId={this.state.params.orderTypeLookupId}
				internalBatches={this.state.params.internalBatches}
				internationalCustomers={this.state.params.internationalCustomers}
				onChangeFormulaType={formulaType=> this.onChangeParam('formulaType', formulaType)}
				onChangeLabTech={labtech => this.onChangeParam('labtech', labtech)}
				//onChangeOrderTypeLookupId={orderTypeLookupId=> this.onChangeParam('orderTypeLookupId', orderTypeLookupId)}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
			/>
			<SalesOrder
				visible={this.state.showModal === 'SalesOrder'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Sales_Order')}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				salesOrders={salesOrders}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<BatchVariance
				visible={this.state.showModal === 'BatchVariance'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Batch_Variance')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				formulas={this.state.customerFormulas}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<LateLossDamageReportForSalesOrder
				visible={this.state.showModal === 'LateLossDamageReportForSalesOrder'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Late_Sales')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				customers={this.state.customers}
				shippers={this.state.shippers}
				openOrders={this.state.params.openOrders}
				onChangeShipper={ shipper=> this.onChangeParam('shipper', shipper)}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeOpenOrders={openOrders => this.onChangeParam('openOrders',openOrders)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<LateLossDamageReportForPurchaseOrder
				visible={this.state.showModal === 'LateLossDamageReportForPurchaseOrder'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Late_Purchase')}
				components={this.state.components}
				vendors={this.state.vendors}
				openOrders={this.state.params.openOrders}
				onChangeOpenOrders={openOrders => this.onChangeParam('openOrders',openOrders)}
				onChangeVendor={ vendor=> this.onChangeParam('vendor', vendor)}
				onChangeComponent={component => this.onChangeParam('component', component)}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<LbsSold
				visible={this.state.showModal === 'LbsSold'}
				onHide={() => {
					this.clearCustomerFormulas()
					this.clearCustomerSalesReps()
					this.setState({showModal: false})
				}}
				onSubmit={() => this.onSubmit('BCM_Lbs_Sold')}
				customers={this.state.customers}
				onChangeCustomer={customer => {
					this.onChangeParam('customer', customer);
				}}
				salesOrders={salesOrders}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				formulas={this.state.customerFormulas}
				salesReps={this.state.salesReps}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeSalesOrder={salesOrder => this.onChangeParam('salesOrder', salesOrder)}
				onChangeBatchTicket={batchticket => this.onChangeParam('batchticket', batchticket)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				dateToUse={this.state.params.dateToUse}
				location={this.state.params.location}
				summary={this.state.params.summary}
				regularBilling={this.state.params.regularBilling}
				consignmentBilling={this.state.params.consignmentBilling}
				orderType={this.state.params.orderType}
				internalOrders={this.state.params.internalOrders}
				newSales={this.state.params.newSales}
				priceBasedUpon={this.state.params.priceBasedUpon}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				labtechs={this.state.labtechs}
				onChangeLabTech={labtech => this.onChangeParam('labtech', labtech)}
			/>
			<Historical
				visible={this.state.showModal === 'Historical'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Historical')}
				jobs={jobs}
				colors={colors}
				presses={presses}
				jobColors={this.state.jobColors}
				pressColors={this.state.pressColors}
				customers={this.state.customers}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				setupMode={this.state.params.setupMode}
				type={this.state.params.type}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChecked={this.onChecked}
				onChangeState={this.onChangeState}
				retired={this.state.retired}
				errors={this.state.errors}
				reportFormat={this.state.params.__format}
			/>
			<ColorMatch
				visible={this.state.showModal === 'ColorMatch'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Color_Match')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				customers={this.state.customers}
				salesReps={this.state.salesReps}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<CallReportSummary
				visible={this.state.showModal === 'CallReportSummary'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_Call_Report_Summary')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				customers={this.state.customers}
				vendors={this.state.vendors}
				shippers={this.state.shippers}
				salesReps={this.state.salesReps}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeVendor={vendor => this.onChangeParam('vendor', vendor)}
				onChangeShipper={shipper => this.onChangeParam('shipper', shipper)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
			/>
			<VOC
				visible={this.state.showModal === 'VOC'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_VOC')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				formulas={this.state.customerFormulas}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
			/>
			<SalesOrderLRShipment 
				visible={this.state.showModal === 'SalesOrderLRShipment'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_SalesOrder_LR_Shipment')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				customers={this.state.customers}
				onChangeCustomer={customer => this.onChangeParam('customer', customer)}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				summary={this.state.params.summary}
				orderType={this.state.params.orderType}
				internalOrders={this.state.params.internalOrders}
			/>
			<TopComponent 
				visible={this.state.showModal === 'TopComponent'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_TopComponent')}
				onChangeStartDate={(startDate) => this.onChangeParam('startDate', startDate)}
				onChangeEndDate={(endDate) => this.onChangeParam('endDate', endDate)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
			/>
			<PhAndViscosity
				visible={this.state.showModal === 'PhAndViscosity'}
				onHide={() => this.setState({showModal: false})}
				onSubmit={() => this.onSubmit('BCM_PH_Viscosity')}
				customers={this.state.customers}
				onChangeCustomer={customer => {
					this.onChangeParam('customer', customer);
			
				}}
				corporates={this.state.corporates}
				onChangeCorporate={corporate => {
					this.onChangeParam('corporate', corporate);
				}}
				formulas={this.state.customerFormulas}
				onChangeInput={(paramName, value) => this.onChangeInput(paramName, value)}
				onChangeFormula={formula => this.onChangeParam('formula', formula)}
				startDate={this.state.params.startDate}
				endDate={this.state.params.endDate}
				internalOrders={this.state.params.internalOrders}
				onChangeReportFormat={format => this.onChangeParam('__format', format)}
				lowPh={this.state.params.lowPh}
				highPh={this.state.params.highPh}
				lowViscosity={this.state.params.lowViscosity}
				highViscosity={this.state.params.highViscosity}
				specOutOfPh={this.state.params.specOutOfPh}
				specOutOfViscosity={this.state.params.specOutOfViscosity}
			/>
		</div>;
	}
}
